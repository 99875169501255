@font-face {
    font-family: 'Adobe Clean';
    src: url('/fonts/1a6a4f01c3b01659d5983f039d5af02f.eot'); /* IE9*/
    src: url('/fonts/1a6a4f01c3b01659d5983f039d5af02f2.eot') format('embedded-opentype'),
        /* IE6-IE8 */ url('/fonts/1a6a4f01c3b01659d5983f039d5af02f.woff2') format('woff2'),
        /* chrome firefox */ url('/fonts/1a6a4f01c3b01659d5983f039d5af02f.woff') format('woff'),
        /* chrome firefox */ url('/fonts/1a6a4f01c3b01659d5983f039d5af02f.ttf') format('truetype'),
        /* chrome firefox opera Safari, Android, iOS 4.2+*/ url('/fonts/1a6a4f01c3b01659d5983f039d5af02f.svg')
            format('svg'); /* iOS 4.1- */
}

@font-face {
    font-family: 'Adobe Clean';
    src: local('/fonts/Adobe Clean Bold'), local('Adobe-Clean-Bold'),
        url('/fonts/AdobeClean-Bold.woff2') format('woff2'), url('/fonts/AdobeClean-Bold.woff') format('woff'),
        url('/fonts/AdobeClean-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

html,
body {
    font-family: 'Adobe Clean' !important;
    color: black !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: inherit !important;
}

:root {
    --primary-color: #18a956;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.table-responsive {
    overflow-x: unset !important;
    overflow-x: auto !important;
    /* overflow: hidden; */
}

.error {
    color: #e03225;
}

.btn {
    margin-bottom: 0.5rem;
}

.form-error-message {
    font-size: 13px;
    padding: 5px 0;
    color: #f44336;
    width: 100%;
    display: block;
}

b,
strong {
    font-weight: bold !important;
}

.text-btn {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    text-decoration: underline;
    color: inherit;
    font-weight: 500;
    font-style: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 7px;
    transform: translateY(0px);
    transition: tranform 500ms ease-out;
}

.text-btn:hover {
    opacity: 0.75;
    transform: translateY(-2px);
}
