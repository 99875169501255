.custom-button {
    background-color: var(--primary-color) !important;
    letter-spacing: 1.4px !important;
    color: #ffffff !important;
    font-size: 14px !important;
    text-transform: uppercase;
    font-weight: bold !important;
    border: 0 !important;
    font-family: 'Adobe Clean' !important;
    height: 40px;
    border-radius: 5px !important;
}
