.barChart {
    width: 65%;
}

.pieChart {
    width: 35%;
}

/* Tablet screens */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .barChart {
        width: 100%;
    }

    .pieChart {
        width: 50%;
    }
}

/* Small screens (phones and small tablets) */
@media only screen and (max-width: 767px) {
    .barChart {
        width: 100%;
    }

    .pieChart {
        width: 80%;
    }
}
